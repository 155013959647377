"use client";

export default function Loading() {
  return (
    <div className={"main-bg w-screen h-screen flex items-center justify-center"}>
      <div className={"flex items-center justify-center"}>
        loading...
        <span className={"text-[#181817] font-bold text-[36px] ml-4"}> </span>
      </div>
    </div>
  );
}
